/**
 * This file kicks off vite tasks to minify and compile CSS and JS.
 */
const flame = String.fromCodePoint(0x1f525)

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log( flame + "HMR")
    });
}

// CSS imports
import './assets/css/main.css'

// JS imports
import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
import './assets/js/_obaia-window-helpers'
import './assets/js/_obaia-window-components'

 // * TODO: THIS IS GIVING SOME NEXT LEVEL HEADACHE
// import gsap from "gsap"
// import barba from '@barba/core'
//
//
// barba.hooks.before((data) => {
//     document.documentElement.classList.add('is-transitioning')
//     // toggleMenuCurrentState();
// });
// barba.hooks.enter((data) => {
//     window.scrollTo(0, 0)
// });
// barba.hooks.after(() => {
//     // console.log(lightning + " ------ AFTER");
//     document.documentElement.classList.remove('is-transitioning')
// });
// barba.init({
//     transitions: [{
//         name: 'opacity-transition',
//         leave(data) {
//             // DEBUG
//             return gsap.to(data.current.container, {
//                 opacity: 0
//             });
//         },
//         enter(data) {
//             // DEBUG
//             return gsap.from(data.next.container, {
//                 opacity: 0
//             });
//         }
//     }],
//     views: [
//         {
//             namespace: 'home',
//             beforeEnter(data) {
//
//             }
//         },
//     ]
// });


Alpine.plugin(focus)
Alpine.plugin(intersect)
Alpine.plugin(collapse)
Alpine.start()

import "./assets/js/_obaia-parallax"
import "./assets/js/_obaia-mouse-chaser"
// fix swiper errors?
// setTimeout(function() {
//     window.dispatchEvent(new Event('resize'))
// }, 1000)
window.addEventListener('load', (event) =>{
    document.documentElement.classList.add('is-loaded')
})
// Components
// import './assets/js/_validator-pristine'
// import './assets/js/_swiper'

// * END OF FILE
console.log(`%c
           
            `+ flame + ` Made with a keyboard by ` + flame + `

 ________      ________      ________      ___      ________     
|\\   __  \\    |\\   __  \\    |\\   __  \\    |\\  \\    |\\   __  \\    
\\ \\  \\|\\  \\   \\ \\  \\|\\ /_   \\ \\  \\|\\  \\   \\ \\  \\   \\ \\  \\|\\  \\   
 \\ \\  \\\\\\  \\   \\ \\   __  \\   \\ \\   __  \\   \\ \\  \\   \\ \\   __  \\  
  \\ \\  \\\\\\  \\   \\ \\  \\|\\  \\   \\ \\  \\ \\  \\   \\ \\  \\   \\ \\  \\ \\  \\ 
   \\ \\_______\\   \\ \\_______\\   \\ \\__\\ \\__\\   \\ \\__\\   \\ \\__\\ \\__\\
    \\|_______|    \\|_______|    \\|__|\\|__|    \\|__|    \\|__|\\|__|


                            WEBDEVELOPMENT
                            https://obaia.nl

`, `font-family: monospace;`)