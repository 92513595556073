import gsap from "gsap";

const mouseChaserElement = document.getElementById('js-obaia-mouse-chaser')
const mouseChaserBackground = mouseChaserElement.querySelector('.js-obaia-mouse-chaser-background')
const mouseChaserText = mouseChaserElement.querySelector('.js-obaia-mouse-chaser-text')
const mouseChaserContent = mouseChaserElement.querySelector('.js-obaia-mouse-chaser-content')
gsap.set(mouseChaserElement, {xPercent: -50, yPercent: -50})

let ball = mouseChaserElement
let pos = {x: window.innerWidth / 2, y: window.innerHeight / 2}
let mouse = {x: pos.x, y: pos.y}
let speed = 0.2

let fpms = 60 / 1000

let xSet = gsap.quickSetter(ball, "x", "px")
let ySet = gsap.quickSetter(ball, "y", "px")

window.addEventListener("mousemove", e => {
    mouse.x = e.x
    mouse.y = e.y
})

const mouseTextFinder = document.querySelectorAll('[data-obaia-mousechaser-text]')
const contentClasses = ['!w-64', '!h-64', '!opacity-100']
for (let index = 0; index < mouseTextFinder.length; index++) {
    const element = mouseTextFinder[index]
    const ballToggleClasses = ['!scale-150', '!h-20', '!w-20', '!sm:cursor-none', element.getAttribute('data-obaia-mousechaser-bg')]
    let elementText = element.getAttribute('data-obaia-mousechaser-text')
    let elementContent = element.getAttribute('data-obaia-mousechaser-content')

    element.classList.add('sm:cursor-none')
    element.addEventListener("mouseenter", () => {
        mouseChaserBackground.classList.add(...ballToggleClasses)
        if (elementContent === 'true') {
            const contentToToggle = element.querySelector('[data-obaia-mousechaser-content]').cloneNode(true)
            contentToToggle.classList.remove('hidden')
            mouseChaserContent.append(contentToToggle)
        }
        mouseChaserContent.classList.add(...contentClasses)
        switch (elementText) {
            case "swiper-arrows":
                return mouseChaserText.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.576 7.637" class="w-14 animate-scroll"><g fill="none" stroke="#fff" stroke-width="1.5"><path d="m19.516 3.819-18.06-.067M4.349 7.107 1.061 3.819 4.349.531"/><g><path d="m32.061 3.818 18.06.067M47.228.53l3.288 3.288-3.288 3.288"/></g></g></svg>'
            case "border":
                mouseChaserBackground.classList.remove(...ballToggleClasses)
                mouseChaserBackground.classList.add(...['w-12', 'h-12', 'border-4', 'border-secondary', '!bg-transparent'])
                return mouseChaserBackground.innerHTML = '<div class="absolute rounded-full m-1 inset-0 bg-secondary"></div>'
            default:
                return mouseChaserText.innerHTML = element.getAttribute('data-obaia-mousechaser-text')
        }
    })
    element.addEventListener("mouseleave", () => {
        mouseChaserText.innerHTML = ''
        mouseChaserContent.innerHTML = ''
        mouseChaserBackground.classList.remove(...ballToggleClasses)
        mouseChaserBackground.classList.remove(...['w-12', 'h-12', 'border-4', 'border-secondary', '!bg-transparent'])
        mouseChaserContent.classList.remove(...contentClasses)
    })
}

// window.addEventListener("click", e => {
//     let ballToggleClasses = ['bg-transparent', 'animate-ping', 'scale-150','h-20','w-20'];

//     ball.children[0].children[1].classList.add(...ballToggleClasses);
//     setTimeout(function() {
//         ball.children[0].children[1].classList.remove(...ballToggleClasses);
//     }, 800);
// });

gsap.ticker.add((time, deltaTime) => {
    let delta = deltaTime * fpms
    let dt = 1.0 - Math.pow(1.0 - speed, delta)
    pos.x += (mouse.x - pos.x) * dt
    pos.y += (mouse.y - pos.y) * dt
    xSet(pos.x)
    ySet(pos.y)
});

// special thanks to Blake Bowen for most of the code.

/*
const circle = document.getElementById('js-mouse-circle');
const onMouseMove = (e) => {
    let eventType = e.type;

    if (eventType == 'mousemove') {
        setInterval(function(){
            circle.style.left = e.pageX + 'px';
            circle.style.top = e.pageY + 'px';
        }, 50);
    }

    if (eventType == 'mouseenter') {
        console.log("MOUSE ENTER");
        circle.classList.remove('is-out-document');
    }
    if (eventType == 'mouseleave') {
        console.log("MOUSE OLEAVE");
        circle.classList.add('is-out-document');
    }
    if (eventType == 'mouseover') {
        console.log("MOUSE OVER!?!??! HOVER?!?!?!");
        circle.classList.add('is-hovered');
    }
}

document.addEventListener('mousemove', onMouseMove);
*/