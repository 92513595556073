// ? These components function with alpine + tailwind UI components
// import Swiper, {Autoplay, EffectCreative, Mousewheel, Navigation, Pagination} from 'swiper'
import Swiper from 'swiper';
import {Autoplay, EffectCreative, Mousewheel, Navigation, Pagination} from 'swiper/modules';
import gsap from "gsap";

window.obaia.Components = {},
    window.obaia.Components.logotoggle = function (e = {
        total: 0,
        resetNumber: 0,
    }) {
        return {
            total: e.total,
            resetNumber: e.resetNumber,
            activeIndex: 1,
            loading: false,
            // circumference: 30 * 2 * Math.PI,
            // circumference: 10 * 2 * Math.PI,
            circumference: 10 * 3.8 * Math.PI,
            percent: 0,
            barFill: 0,
            direction: false,
            beginTimer() {
                this.toggle()

                const animate = () => {
                    switch (this.direction) {
                        case true:
                            this.barFill++
                            break
                        case false:
                            this.barFill--
                            break
                    }
                    this.percent = this.barFill
                }
                let intervalID = setInterval(() => {
                    if (this.barFill === 100 || this.barFill === 0) {
                        this.direction = !this.direction
                        if (!this.direction) {
                            this.toggle()
                        }
                    }
                    animate()

                }, 20); //this sets the speed of the animation

            },
            toggle() {
                this.loading = true
                if (this.activeIndex >= this.resetNumber) {
                    this.activeIndex = 0
                }
                this.activeIndex++
                setTimeout(() => {
                    this.loading = false
                }, 1000)
            }
        }
    },
    window.obaia.Components.marquee = () => {
        return {
            init() {
                function repeatContent(el, till) {
                    let html = el.innerHTML;
                    let counter = 0; // prevents infinite loop
                    while (el.offsetWidth < till && counter < 100) {
                        el.innerHTML += html;
                        counter += 1;
                    }
                }

                let marqueeWrappers = this.$el
                for (let index = 0; index < marqueeWrappers.length; index++) {
                    const marqueeWrapper = marqueeWrappers[index];
                    let marqueeContent = marqueeWrapper.querySelector('.js-marquee-content')
                    repeatContent(marqueeContent, marqueeWrapper.offsetWidth);
                    let marqueeLoop = marqueeWrapper.querySelector('.js-marquee-loop');
                    marqueeLoop.innerHTML = marqueeLoop.innerHTML + marqueeLoop.innerHTML + marqueeLoop.innerHTML;
                }
            }
        }
    },
    window.obaia.Components.horizontalContent = () => {
        return {
            element: '',
            stickyWrapper: '',
            scrollProgress: '',
            scrollProgressVisible: false,
            init() {
                this.element = this.$el
                this.stickyWrapper = this.element.querySelector('.js-sticky-wrapper')
                this.scrollProgress = this.element.querySelector('.js-scroll-progress')
                // setTimeout(() => {
                //     this.scrolling("init")
                // }, 200);
                // let wrap = this.$el
                // let elWrap = wrap.querySelector(".js-sticky-wrapper")
                this.setSize()
            },
            setSize() {
                // this.element.style.height = (this.stickyWrapper.scrollWidth + this.stickyWrapper.offsetWidth) + "px"
                // let horLength = this.stickyWrapper.scrollWidth
                // let distFromTop = this.element.offsetTop
                // let scrollDistance = distFromTop + horLength - windowWidth
                this.stickyWrapper.scrollLeft = 0
                this.element.style.height = (this.stickyWrapper.scrollWidth) + "px"
            },
            resize() {
                this.setSize()
            },
            update(pos) {
                gsap.to(
                    (this.stickyWrapper),
                    {
                        // "transform": "translateX(" + -pos + "px)",
                        scrollLeft: pos,
                        // duration: 0.1,
                        // ease: "elastic.out(1, 0.3)",
                    }
                )
                // this.stickyWrapper.scrollLeft = window.scrollY - this.stickyWrapper.scrollWidth + this.stickyWrapper.offsetTop
                // this.stickyWrapper.scrollLeft = pos
            },
            updateScrollProgress() {
                this.scrollProgress.querySelector('.js-scroll-progress-fill').style.width = 100 * this.stickyWrapper.scrollLeft / (this.stickyWrapper.scrollWidth - this.stickyWrapper.clientWidth) + "%"
            },
            scrollToEnd() {
                // FIXME, deze kloten knop werkt niet op mobile
                window.scroll({
                    top: this.stickyWrapper.scrollWidth
                })
            },
            scrolling(type = undefined) {
                let windowWidth = window.innerWidth
                let scrollTop = window.scrollY

                let horLength = this.stickyWrapper.scrollWidth
                let distFromTop = this.element.offsetTop
                let scrollDistance = distFromTop + horLength - windowWidth
                // this.element.style.height = horLength + "px"
                // this.element.style.height = (this.stickyWrapper.scrollWidth + this.stickyWrapper.offsetWidth) + "px"
                // FIXME, debug this for mobile
                this.updateScrollProgress()
                if (scrollTop > distFromTop && scrollTop < scrollDistance) {
                    this.update((scrollTop - distFromTop))
                    this.scrollProgressVisible = true
                    // this.slideOver = false
                }
                if (type === 'init' && scrollTop >= scrollDistance) {
                    this.update(scrollDistance)
                    this.scrollProgressVisible = false
                    // this.slideOver = true
                }
                if (scrollTop < distFromTop) {
                    // this.update(scrollDistance)
                    this.scrollProgressVisible = false
                    // this.slideOver = false
                }
                if (scrollTop > scrollDistance) {
                    // this.update(scrollDistance)
                    this.scrollProgressVisible = false

                    // if (scrollTop >= (scrollDistance + 400) && scrollTop <= (scrollDistance + 500)) {
                    // this.slideOver = true
                    //    Skip a scrolling section
                    // window.scroll({
                    //     top: scrollTop + 1000,
                    //     behavior: "auto"
                    // })
                    // }
                }
            },
        }
    },
    window.obaia.Components.mouseTracker = function (e = {
        intensity: 10,
        rootElem: null,
    }) {

        return {
            counter: 0,
            updateRate: 2,
            intensity: e.intensity,
            rootElem: e.rootElem,
            mouse: {
                _x: 0,
                _y: 0,
                x: 0,
                y: 0,
                updatePosition: function (e) {
                    this.x = e.clientX - this._x
                    this.y = (e.clientY - this._y) * -1
                },
                updatePositionFromCenterPoint: function (e, target, root) {
                    target = root ?? target
                    this.x = e.clientX - target.getBoundingClientRect().left - (target.getBoundingClientRect().width / 2)
                    this.y = e.clientY - target.getBoundingClientRect().top - (target.getBoundingClientRect().height / 2)
                },
                setOrigin: function (e) {
                    this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2)
                    this._y = e.offsetTop + Math.floor(e.offsetHeight / 2)
                },
                show: function () {
                    return "(" + this.x + ", " + this.y + ")"
                }
            },
            init() {
                this.mouse.setOrigin(this.$el)
            },
            leave() {
                this.updateTransformStyle(0, 0, this.$el)
            },
            updateTransformStyle(x, y, target) {
                // console.log(this.mouse.show())
                gsap.to(
                    (target ?? this.$el),
                    {
                        // "transform": "translate3d(" + (y / 10) + "px," + (x / 10) + "px, 0) rotate3d(" + ( x ) + "," + (y ) + ",0," + ( y / 10 )+"deg)",
                        // "transform": "translate3d(" + (y / 10) + "px," +( x / 10 )+ "px, 0)",
                        "transform": "rotateX(" + (y) + "deg) rotateY(" + (x) + "deg) translateZ(0px)",
                        duration: 2.5,
                        // ease: "slow(2, 2, false)",
                        // ease: "slow(1, 1, false)",
                        ease: "elastic.out(1, 0.3)",
                        // ease: "back.out(1.7)"
                    }
                )
            },
            update(e) {
                const target = this.$el.querySelectorAll('[data-mouse-ref]')
                // const target = this.$el
                for (let i = 0; i < target.length; i++) {
                    this.mouse.updatePositionFromCenterPoint(e, target[i], this.rootElem)
                    this.updateTransformStyle(
                        (this.mouse.x / this.intensity),
                        (this.mouse.y / this.intensity),
                        target[i]
                    )
                }
            },
            isTimeToUpdate() {
                return this.counter++ % this.updateRate === 0
            },
            move(e) {
                if (this.isTimeToUpdate()) {
                    this.update(e)
                }
            },
            enter(e) {
                this.mouse.setOrigin(this.$el)
                this.update(e)
            }
        }
    },
    window.obaia.Components.swiper = (options) => {
        switch (options.creative) {
            case 'hero-home':
                options.creative = {
                    effect: 'creative',
                    creativeEffect: {
                        // progressMultiplier: 0.8,
                        limitProgress: 3,
                        prev: {
                            shadow: false,
                            origin: "center center",
                            translate: ["0%", "0%", -1],
                            rotate: [0, 0, 0],
                            scale: 1
                        },
                        next: {
                            shadow: false,
                            origin: "center center",
                            translate: ["50%", "0%", 1],
                            rotate: [0, 0, 0],
                            scale: 1
                        },
                    }
                }
                break

            case 'expertise-detail':
                options.creative = {
                    effect: 'creative',
                    creativeEffect: {
                        // progressMultiplier: 0.8,
                        limitProgress: 3,
                        prev: {
                            shadow: false,
                            origin: "center center",
                            translate: ["-50%", "0%", -1],
                            rotate: [0, 0, 0],
                            scale: 0.9,
                            opacity: 0
                        },
                        next: {
                            shadow: false,
                            origin: "center center",
                            translate: ["110%", "0%", 1],
                            rotate: [0, 0, 0],
                            scale: 1
                        },
                    },
                }
                break
            case 'artsy':
                options.creative = {
                    effect: 'creative',
                    creativeEffect: {
                        progressMultiplier: 0.8,
                        limitProgress: 6,
                        prev: {
                            shadow: false,
                            origin: "center center",
                            translate: ["-90%", "10%", -1],
                            rotate: [0, 0, 0],
                            scale: 0.9,
                            opacity: 1
                        },
                        next: {
                            shadow: false,
                            origin: "center center",
                            translate: ["90%", "10%", -1],
                            rotate: [0, 0, 0],
                            scale: 1
                        },
                    },
                }
                break
        }
        return {
            swiperConstance: '',
            toggleAutoplay() {
                if (this.swiperConstance.autoplay.running) {
                    this.swiperConstance.autoplay.stop()
                } else {
                    this.swiperConstance.autoplay.start()
                }
            },
            init() {
                let initialSlideIndex = 0
                if (options.initialSlide !== '' && typeof options.initialSlide === 'string') {
                    initialSlideIndex = Array.prototype.indexOf.call(this.$el.querySelectorAll('.swiper-slide'), this.$el.querySelector('#' + options.initialSlide))
                    if (initialSlideIndex == -1) {
                        initialSlideIndex = 0
                    }
                }
                const thisElem = this.$el
                window.addEventListener('load', (event) => {
                    this.swiperConstance = new Swiper(thisElem, {
                        modules: [Navigation, Pagination, Mousewheel, EffectCreative, Autoplay],
                        autoplay: options.autoplay ?? false,
                        speed: options.speed ?? 300,
                        freeMode: options.freeMode ?? false,
                        // cssMode: options.cssMode ?? false,
                        loop: options.loop ?? false,
                        autoHeight: options.autoHeight ?? false,
                        slidesPerView: options.slidesPerView ?? 1,
                        centeredSlides: options.centeredSlides ?? false,
                        spaceBetween: options.spaceBetween ?? 16,
                        initialSlide: options.initialSlide ? initialSlideIndex : options.centeredSlides ? this.$el.querySelectorAll('.swiper-slide').length / 2 : 0,
                        ...options.creative,
                        mousewheel: {
                            forceToAxis: true,
                        },
                        navigation: {
                            prevEl: this.$refs.swiperPrev ?? thisElem.closest('section').querySelector('.js-swiper-button-prev'),
                            nextEl: this.$refs.swiperNext ?? thisElem.closest('section').querySelector('.js-swiper-button-next'),
                        },
                        pagination: options.pagination ? {
                            el: thisElem.closest('section').querySelector('.js-swiper-pagination'),
                            clickable: true,
                            renderBullet: function (index, className) {
                                if (options.renderCustomBullets) {
                                    let names = []
                                    thisElem.querySelectorAll('.swiper-slide').forEach((slide) => {
                                        names.push(slide.getAttribute('data-name'))
                                    })
                                    return '<li class="cursor-pointer uppercase tracking-60 text-base leading-8 font-sans font-bold antialiased ' + className + '">' + names[index] + '</li>';
                                } else {
                                    return '<li class="' + className + '"></li>';
                                }
                            }
                        } : {},
                        breakpoints: {
                            ...options.breakpoints
                        },
                        // on: {
                        // init: function () {
                        //     if (options.autoplay) {
                        //         this.autoplay.start()
                        //     }
                        // },
                        // autoplayResume: function () {
                        //     this.autoplay.start()
                        // },
                        // autoplayPause: function () {
                        //     this.autoplay.stop()
                        // },
                        // },
                    })
                    // this.swiperConstance.slideTo(0)
                    // if (options.loop) {
                    //     this.swiperConstance.loopDestroy()
                    //     this.swiperConstance.loopCreate()
                    // }
                })
            }
        }
    },
    window.obaia.Components.menu = function (e = {
        open: !1
    }) {
        return {
            init() {
                this.items = Array.from(this.$el.querySelectorAll('[role="menuitem"]')),
                    this.$watch("open", (() => {
                            this.open && (this.activeIndex = -1)
                        }
                    ))
            },
            activeDescendant: null,
            activeIndex: null,
            items: null,
            open: e.open,
            focusButton() {
                this.$refs.button.focus()
            },
            onButtonClick() {
                this.open = !this.open,
                this.open && this.$nextTick((() => {
                        this.$refs["menu-items"].focus()
                    }
                ))
            },
            onButtonEnter() {
                this.open = !this.open,
                this.open && (this.activeIndex = 0,
                    this.activeDescendant = this.items[this.activeIndex].id,
                    this.$nextTick((() => {
                            this.$refs["menu-items"].focus()
                        }
                    )))
            },
            onArrowUp() {
                if (!this.open)
                    return this.open = !0,
                        this.activeIndex = this.items.length - 1,
                        void (this.activeDescendant = this.items[this.activeIndex].id);
                0 !== this.activeIndex && (this.activeIndex = -1 === this.activeIndex ? this.items.length - 1 : this.activeIndex - 1,
                    this.activeDescendant = this.items[this.activeIndex].id)
            },
            onArrowDown() {
                if (!this.open)
                    return this.open = !0,
                        this.activeIndex = 0,
                        void (this.activeDescendant = this.items[this.activeIndex].id);
                this.activeIndex !== this.items.length - 1 && (this.activeIndex = this.activeIndex + 1,
                    this.activeDescendant = this.items[this.activeIndex].id)
            },
            onClickAway(e) {
                if (this.open) {
                    const t = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e => `${e}:not([tabindex='-1'])`)).join(",");
                    this.open = !1,
                    e.target.closest(t) || this.focusButton()
                }
            }
        }
    }
    ,
    window.obaia.Components.popoverGroup = function () {
        return {
            __type: "popoverGroup",
            init() {
                let e = t => {
                        document.body.contains(this.$el) ? t.target instanceof Element && !this.$el.contains(t.target) && window.dispatchEvent(new CustomEvent("close-popover-group", {
                            detail: this.$el
                        })) : window.removeEventListener("focus", e, !0)
                    }
                ;
                window.addEventListener("focus", e, !0)
            }
        }
    }
    ,
    window.obaia.Components.popover = function ({open: e = !1, focus: t = !1} = {}) {
        const i = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e => `${e}:not([tabindex='-1'])`)).join(",");
        return {
            __type: "popover",
            open: e,
            init() {
                t && this.$watch("open", (e => {
                        e && this.$nextTick((() => {
                                !function (e) {
                                    const t = Array.from(e.querySelectorAll(i));
                                    !function e(i) {
                                        void 0 !== i && (i.focus({
                                            preventScroll: !0
                                        }),
                                        document.activeElement !== i && e(t[t.indexOf(i) + 1]))
                                    }(t[0])
                                }(this.$refs.panel)
                            }
                        ))
                    }
                ));
                let e = i => {
                        if (!document.body.contains(this.$el))
                            return void window.removeEventListener("focus", e, !0);
                        let n = t ? this.$refs.panel : this.$el;
                        if (this.open && i.target instanceof Element && !n.contains(i.target)) {
                            let e = this.$el;
                            for (; e.parentNode;)
                                if (e = e.parentNode,
                                e.__x instanceof this.constructor) {
                                    if ("popoverGroup" === e.__x.$data.__type)
                                        return;
                                    if ("popover" === e.__x.$data.__type)
                                        break
                                }
                            this.open = !1
                        }
                    }
                ;
                window.addEventListener("focus", e, !0)
            },
            onEscape() {
                this.open = !1,
                this.restoreEl && this.restoreEl.focus()
            },
            onClosePopoverGroup(e) {
                e.detail.contains(this.$el) && (this.open = !1)
            },
            toggle(e) {
                this.open = !this.open,
                    this.open ? this.restoreEl = e.currentTarget : this.restoreEl && this.restoreEl.focus()
            }
        }
    }
    ,
    window.obaia.Components.radioGroup = function ({initialCheckedIndex: e = 0} = {}) {
        return {
            value: void 0,
            active: void 0,
            init() {
                let t = Array.from(this.$el.querySelectorAll("input"));
                this.value = t[e]?.value;
                for (let e of t)
                    e.addEventListener("change", (() => {
                            console.log(e.value);
                            this.active = e.value
                        }
                    )),
                        e.addEventListener("focus", (() => {
                                this.active = e.value
                            }
                        ));
                window.addEventListener("focus", (() => {
                        console.log("Focus change"),
                        t.includes(document.activeElement) || (console.log("HIT"), this.active = void 0
                        )
                    }
                ), !0)
            }
        }
    }